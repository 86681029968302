import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { style: {"display":"flex","flex-direction":"row","align-items":"center"} }
const _hoisted_2 = ["src"]
const _hoisted_3 = { style: {"margin-left":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlaySquareTwoTone = _resolveComponent("PlaySquareTwoTone")!
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_a_auto_complete = _resolveComponent("a-auto-complete")!

  return (_openBlock(), _createBlock(_component_a_auto_complete, {
    value: _ctx.inputValue,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
    options: _ctx.options,
    style: {"width":"200px"},
    "dropdown-menu-style": { overflow: 'auto' },
    onSelect: _ctx.onSelect
  }, {
    option: _withCtx((item) => [
      _createElementVNode("div", _hoisted_1, [
        (item.type === 'audio')
          ? (_openBlock(), _createBlock(_component_PlaySquareTwoTone, { key: 0 }))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              style: {"width":"40px","height":"40px","object-fit":"cover","border-radius":"10px"},
              src: item.faces[0]?.mediaUrl
            }, null, 8, _hoisted_2)),
        _createElementVNode("span", _hoisted_3, _toDisplayString(item.label), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_input_search, {
        placeholder: _ctx.placeholder,
        onChange: _ctx.onChange,
        onSearch: _ctx.onSearch
      }, null, 8, ["placeholder", "onChange", "onSearch"])
    ]),
    _: 1
  }, 8, ["value", "options", "onSelect"]))
}