export const getVideoUrl = (token: string): string => {
  return `${process.env.VUE_APP_BUCKET_URL}/media/public/${process.env.VUE_APP_BUCKET_SERVICE}/video/720p24/${token}.mp4`;
};

// https://klleon-media-dev.s3.amazonaws.com/media/public/klleon_saas__previewer/image/720/image-5273af24-f238-450c-a139-6a736b66ef68.jpg

export const getImageUrl = (token: string): string => {
  return `${process.env.VUE_APP_BUCKET_URL}/media/public/${process.env.VUE_APP_BUCKET_SERVICE}/image/720/${token}.jpg`;
};

export const getAudioUrl = (token: string): string => {
  return `${process.env.VUE_APP_BUCKET_URL}/media/public/${process.env.VUE_APP_BUCKET_SERVICE}/audio/default/${token}.mp3`;
};

export const getExtension = (fileName: string, mediaType: string): string => {
  if (mediaType === 'video') {
    return `${fileName}.mp4`;
  } else if (mediaType === 'image') {
    return `${fileName}.jpg`;
  } else {
    return `${fileName}.mp3`;
  }
};

export const getMediaUrl = (token: string): string => {
  if (token.indexOf('audio') > -1) {
    return getAudioUrl(token);
  } else if (token.indexOf('video') > -1) {
    return getVideoUrl(token);
  } else {
    return getImageUrl(token);
  }
};
