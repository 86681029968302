
import { defineComponent } from 'vue';

import { TableOutlined, AppstoreOutlined } from '@ant-design/icons-vue';

import UploadButtons from '../UploadButtons.vue';
export default defineComponent({
  name: 'TableHandler',
  components: {
    TableOutlined,
    AppstoreOutlined,
    UploadButtons,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'image',
    },
    isMine: {
      type: Boolean,
      required: false,
    },
    listType: {
      type: Boolean,
      required: false,
      default: false,
    },
    isUpload: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: Array as () => Array<string>,
      required: false,
      default: () => ['download', 'delete', 'upload'],
    },
  },
  emits: ['search', 'upload', 'download', 'update:isMine', 'update:listType'],

  setup(props, { emit }) {
    const onChangeChecked = (event: { target: { checked: boolean } }) => {
      emit('update:isMine', event.target.checked);
    };

    const onChangeListType = (event: { target: { value: boolean } }) => {
      emit('update:listType', event.target.value);
    };

    const downloadFiles = () => {
      emit('download');
    };

    const uploadFiles = (files: any) => {
      emit('upload', files);
    };

    const search = (text: string) => {
      emit('search', text);
    };

    return {
      onChangeChecked,
      onChangeListType,
      downloadFiles,
      uploadFiles,
      search,
    };
  },
});
