
import { computed, defineComponent, ref } from 'vue';

import MediaCard from '@/components/cards/MediaCard.vue';

export default defineComponent({
  components: {
    MediaCard,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'image',
    },
    mode: {
      type: Array as () => Array<string>,
      required: true,
    },
    isUpload: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['download', 'upload'],

  setup(props, { emit }) {
    const visible = ref(false);
    const isLoading = ref(false);
    const childrenRef = ref();

    const cancelModal = () => {
      visible.value = false;
    };

    const confirmModal = async () => {
      // isLoading.value = true;
      // const files = await childrenRef.value.onSubmit();
      // isLoading.value = false;
      visible.value = false;
      // emit('upload', files);
    };

    const downloadFiles = () => {
      emit('download');
    };

    const getMediaInfo = computed(() => {
      switch (props.type) {
        case 'video':
          return {
            label: 'Video',
            max: 10,
            mediaType: 'video',
            title: 'Video',
          };
        case 'audio':
          return {
            label: 'Audio',
            max: 10,
            mediaType: 'audio',
            title: 'Audio',
          };

        default:
          return {
            label: 'Image',
            max: 10,
            mediaType: 'image',
            title: 'Image',
          };
      }
    });

    return {
      visible,
      isLoading,
      childrenRef,
      // func
      downloadFiles,
      cancelModal,
      confirmModal,
      // computed
      getMediaInfo,
    };
  },
});
