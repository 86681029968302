
import { computed, defineComponent, ref } from 'vue';

import { UploadOutlined, PlusOutlined } from '@ant-design/icons-vue';
import type { UploadProps } from 'ant-design-vue';
import { useCookies } from 'vue3-cookies';

import axios from '@/utils/axios';
import { getMediaUrl } from '@/utils/mediaUrl';

import { IMediaFile } from '../cards/MediaCard.vue';

function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

interface IuploadProps {
  id: number;
  video_token?: string;
  image_token?: string;
  token: string;
  faces_token: string;
}

export default defineComponent({
  components: {
    UploadOutlined,
    PlusOutlined,
  },
  props: {
    fileList: {
      type: Array as () => IMediaFile[],
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    mediaType: {
      type: String,
      required: false,
      default: 'image',
    },
    isUpload: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTemp: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const previewVisible = ref<boolean>(false);
    const previewMedia = ref<string | undefined>('');

    const onChange: UploadProps['onChange'] = (info) => {
      const newFileList = info.fileList.map((obj) => {
        const uploadProps = obj.response;

        if (typeof uploadProps === 'object') {
          const _props = uploadProps as IuploadProps;
          const changeObject = {
            uid: String(_props.id),
            name: obj.name,
            status: 'done',
            url: _props.image_token ? getMediaUrl(_props.image_token) : null,
            faces: _props.faces_token,
          };
          return changeObject;
        }
        return obj;
      });
      emit('change', { fileList: newFileList });

      // {
      //     }
    };

    const handlePreview: UploadProps['onPreview'] = async (file) => {
      if (!file.originFileObj) {
        previewMedia.value = file.url;
      } else {
        previewMedia.value = (await getBase64(file.originFileObj)) as string;
      }
      previewVisible.value = true;
    };

    // const beforeUpload: UploadProps['beforeUpload'] = () => false;

    const handleCancel = () => {
      previewVisible.value = false;
    };

    const beforeUpload2: UploadProps['customRequest'] = async ({
      onSuccess,
      onError,
      file,
    }) => {
      const getFileName = (filename: string) => {
        if (filename.length > 20) {
          return filename.split('.')[0].substring(0, 20) + '...';
        }
        return filename.split('.')[0].substring(0, 20);
      };
      if (file instanceof File) {
        const convertFile = new File([file], file.name.toLowerCase(), {
          type: file.type,
        });
        const formData = new FormData();

        formData.append('name', getFileName(file.name.split('.')[0]));
        formData.append('fileName', convertFile);
        formData.append('temp', `${props.isTemp}`);

        const getApiUrl = () => {
          switch (props.mediaType) {
            case 'image':
              return '/images/';
            case 'video':
              return '/video/';
            default:
              return '/audios/';
          }
        };
        axios
          .post(getApiUrl(), formData)
          .then((res) => {
            if (res.data) {
              onSuccess?.(res.data);
            }
          })
          .catch((err) => {
            if (err.response.status === 415) {
              axios
                .post(getApiUrl(), formData)
                .then((res) => {
                  if (res.data) {
                    onSuccess?.(res.data);
                  }
                })
                .catch((err) => {
                  const error = new Error('Some error');
                  onError?.(error);
                });
            } else {
              const error = new Error('Some error');
              onError?.(error);
            }
          });
      }
    };

    const getComponentProps = computed(() => {
      switch (props.mediaType) {
        case 'image':
          return {
            listType: 'picture-card',
            accept: `${props.mediaType}/*`,
            componentName: 'a-image',
          };
        case 'audio':
          return {
            listType: 'picture',
            accept: `${props.mediaType}/*`,
            componentName: 'audio',
          };
        default:
          return {
            listType: 'picture',
            accept: `${props.mediaType}/*`,
            componentName: 'video',
          };
      }
    });

    return {
      // state
      previewVisible,
      previewMedia,

      // func
      onChange,
      handlePreview,
      // beforeUpload,
      beforeUpload2,
      handleCancel,
      // computed
      getComponentProps,
    };
  },
});
