
import { defineComponent, ref } from 'vue';

import { PlaySquareTwoTone } from '@ant-design/icons-vue';

import axios from '@/utils/axios';
import { getImageUrl } from '@/utils/mediaUrl';

interface Option {
  value: string;
  label: string;
}
export default defineComponent({
  components: {
    PlaySquareTwoTone,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'image',
    },
    placeholder: {
      type: String,
      required: false,
      default: '검색',
    },
  },
  emits: ['select'],
  setup(props, { emit }) {
    const inputValue = ref('');
    const options = ref<Option[]>([]);

    const onSelect = (val: string, option: Option) => {
      inputValue.value = '';
      emit('select', option);
    };

    const onChange = () => {
      options.value = [];
    };

    const onSearch = async () => {
      const getApiUrl = () => {
        switch (props.type) {
          case 'image':
            return { url: '/images/', type: 'image' };
          case 'audio':
            return { url: '/audios/', type: 'audio' };
          default:
            return { url: '/video/', type: 'video' };
        }
      };

      const { url, type } = getApiUrl();
      const { data } = await axios.get(`${url}`, {
        params: { name: inputValue.value, limit: 100, page: 1 },
      });
      options.value = data.items.map(
        (obj: {
          id: string;
          faces: { mediaUrl: string }[];
          name: string;
          mediaUrl: string;
        }) => {
          switch (type) {
            case 'audio':
              return {
                id: obj.id,
                label: obj.name,
                value: obj.mediaUrl,
                faces: undefined,
                type,
              };
            case 'video':
              return {
                id: obj.id,
                label: obj.name,
                value: obj.mediaUrl,
                faces: obj.faces,
                type,
              };
            default:
              return {
                id: obj.id,
                label: obj.name,
                value: obj.mediaUrl,
                faces: obj.faces,
                type,
              };
          }
        }
      );
    };
    return {
      getImageUrl,
      // state
      inputValue,
      options,
      // func
      onChange,
      onSearch,
      onSelect,
    };
  },
});
