import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, renderList as _renderList, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 2,
  style: {"display":"flex","gap":"5px"}
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { style: {"flex":"1 0 auto","display":"flex","margin-right":"10px"} }
const _hoisted_6 = { style: {"width":"100%","overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_handler = _resolveComponent("table-handler")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_video_modal = _resolveComponent("video-modal")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_card_meta = _resolveComponent("a-card-meta")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_table_handler, {
      isMine: _ctx.isMine,
      "onUpdate:isMine": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isMine) = $event)),
      listType: _ctx.listType,
      "onUpdate:listType": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.listType) = $event)),
      "is-loading": _ctx.isLoading,
      type: _ctx.type,
      mode: _ctx.tableHandlerProps.mode,
      "is-upload": _ctx.isUpload,
      onSearch: _ctx.search,
      onDownload: _ctx.download,
      onUpload: _ctx.onUpload
    }, null, 8, ["isMine", "listType", "is-loading", "type", "mode", "is-upload", "onSearch", "onDownload", "onUpload"]),
    (_ctx.listType)
      ? (_openBlock(), _createBlock(_component_a_table, {
          key: 0,
          "row-key": "token",
          "row-selection": _ctx.rowSelection,
          pagination: {
      total: _ctx.count,
      onChange: _ctx.onChangePagination,
      current: _ctx.page,
      pageSize: _ctx.pageSize,
    },
          "data-source": _ctx.apiData,
          loading: _ctx.isLoading,
          columns: _ctx.mergedColumns,
          class: "ant-table-striped",
          "row-class-name": _ctx.getRowClassName
        }, {
          bodyCell: _withCtx(({ column, record }) => [
            (column.key === 'mediaUrl')
              ? _renderSlot(_ctx.$slots, "default", {
                  key: 0,
                  record: record.mediaUrl
                }, () => [
                  (_ctx.type === 'image')
                    ? (_openBlock(), _createBlock(_component_a_image, {
                        key: 0,
                        width: 80,
                        src: record.mediaUrl,
                        style: {"border-radius":"10px"}
                      }, null, 8, ["src"]))
                    : (_ctx.type === 'audio')
                      ? (_openBlock(), _createElementBlock("audio", {
                          key: 1,
                          style: {"max-width":"300px"},
                          controls: "",
                          src: record.mediaUrl
                        }, null, 8, _hoisted_1))
                      : (_openBlock(), _createBlock(_component_video_modal, {
                          key: 2,
                          url: record.mediaUrl
                        }, null, 8, ["url"]))
                ])
              : (column.key === 'timestamp')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(new Date(record.timestamp).toLocaleString('ko-KR')), 1)
                  ], 64))
                : (column.key === 'faces')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.faces, (face) => {
                        return (_openBlock(), _createBlock(_component_a_image, {
                          key: face.id,
                          style: {"display":"flex","border-radius":"10px","width":"80px","height":"80px","object-fit":"contain","background-color":"black"},
                          src: face.mediaUrl
                        }, null, 8, ["src"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["row-selection", "pagination", "data-source", "loading", "columns", "row-class-name"]))
      : (_openBlock(), _createBlock(_component_a_list, {
          key: 1,
          pagination: _ctx.pagination,
          grid: { gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 5, xxxl: 6 },
          "data-source": _ctx.apiData
        }, {
          renderItem: _withCtx(({ item }) => [
            _createVNode(_component_a_list_item, { style: {"align-self":"stretch"} }, {
              default: _withCtx(() => [
                _createVNode(_component_a_card, null, {
                  cover: _withCtx(() => [
                    (_ctx.type === 'image')
                      ? (_openBlock(), _createBlock(_component_a_image, {
                          key: 0,
                          style: {"height":"200px","object-fit":"contain","border-radius":"10px"},
                          src: _ctx.getImageUrl(item.token)
                        }, null, 8, ["src"]))
                      : (_ctx.type === 'audio')
                        ? (_openBlock(), _createElementBlock("audio", {
                            key: 1,
                            controls: "",
                            src: _ctx.getAudioUrl(item.token)
                          }, null, 8, _hoisted_3))
                        : (_openBlock(), _createElementBlock("video", {
                            key: 2,
                            playsinline: "",
                            style: {"height":"200px","background-color":"black"},
                            controls: "",
                            src: _ctx.getVideoUrl(item.token)
                          }, null, 8, _hoisted_4))
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_a_card_meta, {
                      title: item.name || item.filename
                    }, {
                      description: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listColumns, (info, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: `label-${index}`,
                            style: {"display":"flex"}
                          }, [
                            _createElementVNode("span", _hoisted_5, _toDisplayString(info.title) + ": ", 1),
                            _createElementVNode("span", _hoisted_6, _toDisplayString(item[info.key || '']), 1)
                          ]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["title"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["pagination", "data-source"]))
  ], 64))
}