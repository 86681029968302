
export interface IMediaFile extends UploadFile {
  name: string;
  message?: string;
  faces?: { id: string; mediaUrl: string }[];
}

import { defineComponent, ref } from 'vue';

import type { UploadFile, UploadProps } from 'ant-design-vue';

import { getExtension } from '@/utils/mediaUrl';

import OriginalMediaSearch from '../OriginalMediaSearch.vue';
import Upload from '../uploads/Upload.vue';

export default defineComponent({
  name: 'MediaCard',
  components: { OriginalMediaSearch, Upload },
  props: {
    mediaType: {
      type: String,
      required: false,
      default: 'image',
    },
    title: {
      type: String,
      required: false,
      default: 'Image',
    },
    search: {
      type: Boolean,
      required: false,
      default: false,
    },
    isUpload: {
      type: Boolean,
      required: false,
      default: false,
    },
    uploaderProps: {
      type: Object as () => {
        isTemp: boolean;
        disabled?: boolean;
        max: number;
        min?: number;
      },
      required: false,
      default: () => {
        return { isTemp: true, max: 1, min: 1 };
      },
    },
  },
  emits: ['change', 'select'],
  setup(props, { emit }) {
    const mediaUploadRef = ref();
    const fileList = ref<IMediaFile[]>([]);
    const disabled = ref(true);

    const onChange: UploadProps['onChange'] = (info) => {
      fileList.value = info.fileList;
      emit('change', info);
    };

    const onSelect = (option: {
      id: number;
      label: string;
      value: string;
      faces: { id: string; mediaUrl: string }[];
    }) => {
      if (fileList.value?.length < props.uploaderProps.max) {
        emit('select', option.value);
        fileList.value.push({
          uid: String(option.id),
          name: getExtension(option.label, props.mediaType),
          status: 'done',
          url: option.value,
          faces: option.faces,
        });
      }
      if (props.uploaderProps.max === 1 && fileList.value.length === 1) {
        fileList.value = [
          {
            uid: String(option.id),
            name: getExtension(option.label, props.mediaType),
            status: 'done',
            url: option.value,
            faces: option.faces,
          },
        ];
      }
      emit('change', { fileList: fileList.value });
    };

    const onSubmit = async () => {
      return { [props.mediaType]: fileList.value };
    };

    return {
      // state
      disabled,
      fileList,
      mediaUploadRef,
      // func
      onChange,
      onSelect,
      onSubmit,
    };
  },
});
