
import { defineComponent, ref } from 'vue';

import { PlayCircleTwoTone, CloseCircleOutlined } from '@ant-design/icons-vue';

import { getImageUrl, getVideoUrl } from '@/utils/mediaUrl';

export default defineComponent({
  components: {
    PlayCircleTwoTone,
    CloseCircleOutlined,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  setup() {
    const isActive = ref(false);
    const isHover = ref(false);

    const isActiveModal = () => {
      isActive.value = true;
    };
    return {
      // state
      isActive,
      isHover,
      // func
      getImageUrl,
      getVideoUrl,
      isActiveModal,
    };
  },
});
