
import {
  computed,
  defineComponent,
  h,
  onBeforeMount,
  reactive,
  ref,
  toRefs,
  unref,
} from 'vue';

import { Modal } from 'ant-design-vue';
import { useCookies } from 'vue3-cookies';

import axios from '@/utils/axios';
import { getAudioUrl, getVideoUrl, getImageUrl } from '@/utils/mediaUrl';

import VideoModal from '../medias/VideoModal.vue';
import TableHandler from './TableHandler.vue';

interface ITableColumn {
  title: string;
  key: string;
  dataIndex?: string;
  width?: number;
}

interface IListColumn {
  title: string;
  key: string;
}

const commonColumns: ITableColumn[] | undefined = [
  {
    title: 'Made by',
    key: 'userName',
    dataIndex: 'userName',
    width: 200,
  },
  // {
  //   title: '생성일',
  //   key: 'timestamp',
  //   dataIndex: 'timestamp',
  //   width: 200,
  // },
];

export default defineComponent({
  name: 'UploadImage',
  components: { TableHandler, VideoModal },
  props: {
    isUpload: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'video',
    },
    columns: {
      type: Array as () => ITableColumn[] | [],
      required: true,
    },
    apis: {
      type: Object as () => {
        list: string;
        search?: string;
        searchTag?: string;
      },
      required: true,
    },
    listColumns: {
      type: Array as () => IListColumn[],
      required: false,
      default: () => [],
    },
    tableHandlerProps: {
      type: Object as () => {
        mode: string[];
      },
      required: false,
      default: () => {
        return {
          mode: ['download', 'delete', 'upload'],
        };
      },
    },
  },
  setup(props) {
    const state = reactive({
      isMine: true,
      listType: true,
    });
    const apiData = ref<any[]>([]);

    const page = ref<number>(1);
    const pageSize = ref(10);

    const pagination = {
      pageSize: 30,
      simple: true,
    };

    const count = ref();

    const searchText = ref<string>();
    const selected = ref<
      {
        token: string;
        name?: string;
        filename?: string;
      }[]
    >([]);
    const isLoading = ref<boolean>(false);

    const onSelectChange = (
      _changableRowKeys: string[],
      selectedRows: {
        token: string;
        name: string;
      }[]
    ) => {
      selected.value = selectedRows;
    };

    const search = (text: string) => {
      isLoading.value = true;
      searchText.value = text;
      getApiData(searchText.value);
      page.value = 1;
    };

    const onChangePagination = (_page: number) => {
      getApiData(searchText.value, _page);
      page.value = _page;
    };

    const download = () => {
      if (!selected.value.length) {
        Modal.error({
          title: 'file is not selected',
          content: '파일을 선택해주세요.',
        });
      } else {
        Modal.confirm({
          title: '이 파일들 다운로드 할거임?',
          content: h(
            'div',
            {},
            selected.value.map(({ name, filename }) => {
              return h('p', name || filename);
            })
          ),
          onOk: async () => {
            const files = selected.value.map(async ({ name, token }) => {
              if (props.type === 'video') {
                return { name: name, url: getVideoUrl(token) };
              }
              if (props.type === 'audio') {
                return { name: name, url: getAudioUrl(token) };
              }
              return { name: name, url: getImageUrl(token) };
            });
            for await (const file of files) {
              const data = await axios.get(file.url, {
                responseType: 'blob',
              });
              let fileType = data.data.type.split('/')[1];
              if (data.data.type === 'audio/mpeg') {
                fileType = 'mp3';
              }
              const url = window.URL.createObjectURL(new Blob([data.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', file.name + '.' + fileType);
              link.style.cssText = 'display:none';
              document.body.appendChild(link);
              link.click();
              link.remove();
            }
          },
        });
      }
    };

    const getApiData = async (searchText?: string, page = 1) => {
      let searchParams;
      if (searchText) {
        searchParams = { name: searchText };
      }
      searchParams = { page, limit: 10, ...searchParams, me: state.isMine };
      const { data } = await axios.get(`${props.apis.list}`, {
        params: searchParams,
      });
      isLoading.value = false;

      if (!Array.isArray(data)) {
        apiData.value = data.items;
        count.value = data.meta.totalItems;
      } else {
        apiData.value = data;
      }
    };

    const onUpload = () => {
      getApiData();
    };

    const getRowClassName = (row: any) => {
      if (props.apis.list.indexOf('virtualskin') > -1) {
        return '';
      }
      if (row.token?.indexOf('audio') > -1) {
        return '';
      }
      // if (row.token === null) {
      //   return 'danger';
      // }
      if (!row.response) {
        return 'danger';
      }
    };

    const rowSelection = computed(() => {
      const selectedRowKeys = selected.value.map(({ token }) => token);
      return {
        selectedRowKeys: unref(selectedRowKeys),
        onChange: onSelectChange,
        hideDefaultSelections: true,
      };
    });

    onBeforeMount(async () => {
      getApiData();
    });

    return {
      ...toRefs(state),
      isLoading,
      apiData,
      mergedColumns: [...props.columns, ...commonColumns],
      rowSelection,
      count,
      page,
      pageSize,
      // getCreator,
      selected,
      pagination,
      // funcs
      getAudioUrl,
      getVideoUrl,
      getImageUrl,
      download,
      search,
      onUpload,
      onChangePagination,
      // computed
      getRowClassName,
      getApiData,
    };
  },
});
