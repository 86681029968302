import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { style: {"display":"flex","flex-direction":"row","justify-content":"space-between"} }
const _hoisted_2 = { style: {"display":"flex"} }
const _hoisted_3 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_4 = { style: {"display":"flex","align-items":"center","margin":"5px 0 0 10px","font-size":"8px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OriginalMediaSearch = _resolveComponent("OriginalMediaSearch")!
  const _component_Upload = _resolveComponent("Upload")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { style: {"flex":"1"} }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString((_ctx.fileList || []).length) + " / " + _toDisplayString(_ctx.uploaderProps.max), 1)
        ]),
        (_ctx.search)
          ? (_openBlock(), _createBlock(_component_OriginalMediaSearch, {
              key: 0,
              placeholder: `${_ctx.mediaType} Search`,
              type: _ctx.mediaType,
              onSelect: _ctx.onSelect
            }, null, 8, ["placeholder", "type", "onSelect"]))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Upload, _mergeProps({
        ref: "mediaUploadRef",
        "media-type": _ctx.mediaType,
        "is-upload": _ctx.isUpload
      }, _ctx.uploaderProps, {
        "file-list": _ctx.fileList,
        onChange: _ctx.onChange
      }), null, 16, ["media-type", "is-upload", "file-list", "onChange"])
    ]),
    _: 1
  }))
}